import React, {Component} from "react";
import 'animation.gsap'
import 'debug.addIndicators'
// import '../resources/styles/pages/_custom-made-systems.scss'
import Layout from "../components/layout"
import Subgrid from "../components/subgrid"


// import 'animation.gsap'
// import 'debug.addIndicators'
// import '../resources/styles/pages/_website-development.scss'
import Utils from "../utils/utils";
import CodicateImage from "../components/codicate-image/index";

// import 'animation.gsap'
// import 'debug.addIndicators'
import ScrollMagic from 'scrollmagic';
import {graphql} from 'gatsby'

import building from "../resources/images/custom-made-systems/custom-systems-building.svg"
import team from "../resources/images/custom-made-systems/team.svg"
import ImageAscii,{asciiScrollAnimation} from "../components/image-ascii";
import {IS_MOBILE} from "../utils/constants";


const TweenMax = require('TweenMax');
class CustomMadeSystemsPage extends Component {

    constructor(props) {
        super(props);

        this.scene = [];
        this.state = {
            isMobile: false
        }

    }


    componentDidMount() {
        this.setState({isMobile: IS_MOBILE})


        // init controller
        const controller = new ScrollMagic.Controller();

        //maze animation
        const mazeAnimationOffset =  Utils.calcSectionOffset(this.sectionsTiming, 1);
        this.scene.push(new ScrollMagic.Scene({offset: mazeAnimationOffset, duration: this.sectionsTiming[1].duration})
            .setTween('#mazeLine', {strokeDashoffset: 0, ease: TweenMax.Linear.easeNone})
            // .addIndicators({name: '1 - background up'}) // add indicators
            .addTo(controller));


        asciiScrollAnimation(controller,this.scene,this.sectionsTiming);



    }

    componentWillUnmount() {

        this.scene.forEach(function (el) {
            el.destroy(true);
            el = null;
        });


    }

    sectionsTiming = [
        {
            name: 'section-1',
            duration: Utils.calcDuration(0.5),
            animate: true
        },
        {
            name: 'section-2',
            duration: Utils.calcDuration(1),
            animate: true
        },
        {
            name: 'section-3',
            duration: Utils.calcDuration(2),
            animate: false
        },
        {
            name: 'section-4',
            duration: Utils.calcDuration(1),
            animate: true
        },
    ];


    textSlides = [
        {
            className: 'custom-made-systems',
            content:
                <>
                    <strong id="large-service-title">Custom Made Systems</strong>
                    <span className="subTitle">From strategy to launch. End-to-end solution for developing your unique software</span>
                    {/*<span className="subTitle">We’ve seen it all.</span>*/}

                    <div className="icons-container">
                        <span className="react"><CodicateImage img={this.props.data.reactIcon} alt="react icon"/></span>
                        <span className="separator"/>
                        <span className="python"><CodicateImage img={this.props.data.pythonIcon} alt="python icon"/></span>
                        <span className="separator"/>
                        <span className="node"><CodicateImage img={this.props.data.nodeIcon} alt="nodejs icon"/></span>
                    </div>
                </>
        },
        {
            className: 'innovative-thinking',
            content:
                <>
                    <span className="small-title">CUSTOM MADE SYSTEM</span>

                    <strong id="large-service-title">Innovative thinking and technology</strong>
                    <span
                        className="subTitle">We love tech challenges and finding the creative solutions for them</span>

                </>
        },
        {
            className: 'your-budget',
            content:
                <>
                    <span className="small-title">CUSTOM MADE SYSTEM</span>

                    <strong id="large-service-title">Development -</strong>
                    <strong>on your budget</strong>
                    <span className="subTitle">You set the budget, <b>we will make it work</b></span>

                </>
        },
        {
            className: 'done-as-a-service',
            content:
                <>
                    <span className="small-title">CUSTOM MADE SYSTEM</span>

                    <strong id="large-service-title">Done as a Service</strong>
                    <span className="subTitle">Boost your development with an agile, startup minded team</span>

                </>
        },

    ]

    imagesSlides = () => {
        return [
        {
            className: 'building',
            content:
                <CodicateImage
                    img={building}
                    alt=""
                />
        },
        {
            className: 'maze',
            content:
                <>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                         width="1280.534px" height="689px" viewBox="-52 0 1280.534 689"
                    >
<path fill="#264AA6" d="M1229,689H0V344.384h16.162v229.745h80.815v-32.823h96.975v-82.049H96.977V213.101H64.651
	v278.977h96.978v16.407H64.65v32.82H48.49V196.692h64.649v246.152h32.326V196.692h48.487v-82.053h-48.487V98.23h48.487V49h16.164
	v49.23h145.467v16.41H210.116v82.052h226.277V49h16.166v426.667h32.324v-65.644h16.162v82.055h-64.652V213.101h-80.811v229.743
	h-16.164V213.101h-32.326v426.667H290.93V213.101h-32.324v65.644h-16.164V213.1h-80.813v98.459h32.323v-65.639h16.164v65.64h48.49
	v196.924h-16.164V327.973h-80.813v114.871h32.323v-82.05h16.164v180.512h48.49v16.411h-48.49v82.051h-16.164v-82.05H113.14v32.82
	H16.162v32.819h96.977v16.41H16.162v32.823h129.303v-82.053h16.164v82.053h80.813v-82.053h16.164v82.053h80.813V475.667h48.488
	V245.922h16.162v246.156h-48.486v32.816h145.463v16.411H355.583v131.285h129.3v-32.823h-96.976v-16.41h113.139v49.233h80.813
	v-49.233h16.163v49.233h80.813v-32.823H630.35v-65.64h16.163v49.229h32.324v-98.462h16.327v-0.001v98.463h32.324v-49.229h16.163
	v65.639h-48.487v32.823h80.813v-49.233h16.163v49.233h80.813v-49.233h113.14v16.41h-96.978v32.823h129.301V541.306H872.954v-16.411
	h145.463v-32.816h-48.486V245.922h16.162v229.745h48.488V672.59h80.812v-82.053h16.164v82.053h80.813v-82.053h16.164v82.053
	 M1229,459.257h-48.952v82.049H1229 M1229.86,557.717h-49.812v82.05h-16.164v-82.05h-48.49v-16.412h48.49V360.793h16.164v82.051
	h32.323V327.973h-80.813v180.513h-16.164V311.561h48.49v-65.639h16.164v65.641h32.323v-98.46h-80.813v65.643h-16.164V213.1h-32.324
	v426.667h-16.162V213.101h-32.326v229.743h-16.164V213.101h-80.812v278.977h-64.651v-82.055h16.162v65.644h32.323V49h16.166v147.692
	h226.278v-82.053h-145.467V98.23h145.467V49h16.164v49.23h48.486v16.41h-48.486v82.052h48.486v246.152h1.326 M1228.535,16.41v49.23
	h-16.162V16.41h-80.815v49.23H1018.42V49.231h96.978V16.409H986.093v131.283h145.465v16.41H969.931V16.408h-80.814V98.46h-16.162
	V16.41h-80.812v82.05h-16.164V16.41h-80.813v82.05h32.326V49.232h16.161v82.05h80.813v-82.05h16.164v82.05h48.487v16.41H840.63
	v180.513h-16.164v-82.051H792.14v114.872h80.813V180.513h16.162v196.921H840.63v65.642h-16.164v-65.642H792.14v98.463h48.488v98.462
	h145.464v16.408H840.63V640h-16.164v-49.232h-48.488v-16.409h48.488v-32.82H727.49v-16.411h96.975v-32.819h-48.487V246.154h-32.326
	v246.154H727.49V229.745h96.975v-82.053H727.49v-32.82h-32.326v65.64h96.977v16.41h-96.977v295.386h-16.328l0,0V196.922H581.86
	v-16.41h96.977v-65.64H646.51v32.82h-96.975v82.053h96.975v262.562h-16.16V246.154h-32.325v246.154h-48.488v32.819h96.975v16.411
	h-96.975v32.82h48.488v16.409h-48.488V640h-16.164v-49.232H387.907v-16.409H533.37v-98.461h48.488v-98.463h-32.324v65.642H533.37
	v-65.642h-48.487V180.513h16.162v180.512h80.813V246.154h-32.324v82.05H533.37V147.693h-48.487v-16.41h48.487v-82.05h16.164v82.05
	h80.813v-82.05h16.161V98.46h32.326V16.41h-80.812v82.05h-16.164V16.41h-80.813v82.05h-16.162V16.41h-80.814V164.1H242.442v-16.409
	h145.465V16.409H258.604v32.823h96.977v16.407H242.44V16.41h-80.814v49.23h-16.162V16.41H16.162v82.05h80.815V65.64H48.49V49.231
	h64.649v65.64H16.162v32.82h145.465v16.41H16.162v147.691H0V0h1229"/>
                        <path id="mazeLine" fill="none" stroke="#FFC42E" strokeWidth="10" strokeMiterlimit="10" d="M-35.916,329.755l59.347-1
	c5.418,0,9.827-7.491,9.827-13.01v-114.93c0-11.162,4.914-20.246,15.871-20.246h115.157c5.418,0,12.825-7.487,12.825-13.007v-22.565
	c0-5.52-7.407-13.009-12.825-13.009h-18.005c-10.957,0-15.872-6.081-15.872-17.245V100c0-11.164,4.915-18.245,15.872-18.245h19.224
	c5.418,0,12.823-7.49,12.823-13.009V50.243c0-11.162,3.917-17.243,14.874-17.243h16.375c10.959,0,16.873,5.081,16.873,16.243v20.374
	c0,5.517,7.407,13.006,12.825,13.006h115.312c10.957,0,15.871,6.082,15.871,17.246v16.114c0,11.164-5.914,17.245-16.871,17.245
	H239.274c-5.418,0-12.825,7.49-12.825,13.009v22.542c0,5.519,7.407,12.008,12.825,12.008h166.874c5.416,0,12.824-7.49,12.824-13.008
	V50.243C418.973,39.081,424.888,33,435.845,33h13.232c10.957,0,19.873,9.081,19.873,20.243v330.425c0,5.52,4.408,10.01,9.824,10.01
	h17.524c10.957,0,19.871,9.08,19.871,20.242v124.842c0,11.164-8.914,20.246-19.871,20.246H381.564c-5.42,0-9.826,4.49-9.826,10.01
	v29.309c0,5.52,4.406,10.008,9.826,10.008h118.947c10.957,0,19.871,9.082,19.871,20.244v20.18c0,5.52,4.41,10.008,9.828,10.008
	h25.016c5.416,0,9.824-4.49,9.824-10.008v-20.984c0-11.162,8.914-20.244,19.873-20.244h18.123c2.664,0,5.157-1.064,7.02-3.008
	c1.861-1.934,2.871-4.531,2.803-7.213l-0.371-18.086c-0.111-5.486,1.902-10.666,5.67-14.588c3.771-3.922,8.812-6.08,14.199-6.08
	h16.631c5.418,0,9.826-4.488,9.826-10.01v-18.719c0-11.164,8.914-20.244,19.873-20.244h15.084l0,0c0.707,0,6.303,0.043,6.303,0.043
	c6.752,0.141,13.561,11.283,13.561,20.201v18.721c0,5.52,4.406,10.01,9.822,10.01h17.623c5.385,0,10.426,2.156,14.193,6.08
	c3.768,3.92,5.779,9.1,5.668,14.586l-0.371,18.086c-0.068,2.682,0.941,5.279,2.801,7.213c1.863,1.941,4.355,3.008,7.02,3.008h17.111
	c10.955,0,19.865,9.082,19.865,20.242v20.986c0,5.52,4.406,10.008,9.82,10.008h29.008c5.416,0,9.822-4.49,9.822-10.008v-20.18
	c0-11.162,8.91-20.244,19.863-20.244h114.898c5.418,0,9.822-4.49,9.822-10.008v-29.309c0-5.52-4.404-10.01-9.822-10.01H876.104
	c-10.953,0-19.863-9.082-19.863-20.246v-124.84c0-11.164,8.91-20.244,19.863-20.244h17.516c5.416,0,9.82-4.49,9.82-10.01V53.243
	C903.441,42.081,912.354,33,923.307,33h10.223c10.953,0,19.863,9.081,19.863,20.243V170.78c0,5.519,4.408,10.008,9.82,10.008
	h172.803c5.416,0,9.82-4.49,9.82-10.008v-27.542c0-5.52-4.404-10.01-9.82-10.01h-114.262c-10.953,0-19.863-9.08-19.863-20.244
	v-10.115c0-11.164,8.91-20.246,19.863-20.246h114.262c5.416,0,9.82-4.49,9.82-10.006V53.243c0-11.162,8.91-20.243,19.865-20.243
	h8.367c10.951,0,19.863,9.081,19.863,20.243v18.503c0,5.52,4.402,10.009,9.818,10.009h18.334"/>
</svg>
                </>
        },
        {
            className: 'screens',
            content:
                <CodicateImage blur
                    img={this.state.isMobile ? this.props.data.screensMobile : this.props.data.screens}
                />
        },
        {
            className: 'wxg-screen',
            content:
                //<img src={team} alt="" />
                <>
                    <ImageAscii
                        image={team}
                        mask={team}
                        asciiImage={this.props.data.asciiImage}
                    />
                </>
        },
    ]}
    render() {
        const footerOffset = Utils.calcFooterOffset(this.sectionsTiming);


        return <Layout
            className="page-custom-made-systems redBg"
            isIncludeFooter={true}
            footerOffset={footerOffset}
            yoastData={this.props.data.wordpressPage.yoast || null}
            seoTitle={this.props.data.wordpressPage.acf.seoTitle}
            pageTitle={this.props.data.wordpressPage.title}
        >
            <Subgrid
                textSlides={this.textSlides}
                imagesSlides={this.imagesSlides()}
                sectionsTiming={this.sectionsTiming}
                pageTitle={'Custom Made Systems'}
            />
        </Layout>
    }
}

export default CustomMadeSystemsPage


export const query = graphql`
    query {
        wordpressPage(template: {eq: "custom-made-systems.php"}){
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
            }
        }
        screens: file(relativePath: { eq: "custom-made-systems/custom-systems-screens.png"}) {
            childImageSharp {
                fluid(maxWidth: 1500){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        screensMobile: file(relativePath: { eq: "custom-made-systems/custom-systems-screens-mobile.png"}) {
            childImageSharp {
                fluid(maxWidth: 1500){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        nodeIcon: file(relativePath: { eq: "custom-made-systems/node-white.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        pythonIcon: file(relativePath: { eq: "custom-made-systems/python-white.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        reactIcon: file(relativePath: { eq: "custom-made-systems/react-white.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        
        
        coverPx: file(relativePath: { eq: "custom-made-systems/pixels-to-app-store.png"}) {
            childImageSharp {
                fluid(maxWidth: 1300){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        
        asciiOriginalImage: file(relativePath: { eq: "custom-made-systems/wxg-screen.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 
        asciiImage: file(relativePath: { eq: "custom-made-systems/ascii.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 
    }
`;
